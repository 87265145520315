import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import Api from "../../../api";
import { toastNotification } from "../../../utils/toastNotification";
import { dummyRequest } from "../../../utils/dummyRequest";

const CreateKeyFigures = ({
  isVisible,
  onClose,
  generalId,
  handleGetKeyFigures,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const handleSubmit = (formValues) => {
    const formData = new FormData();

    formData.append("general_id", generalId);
    formData.append("files", formValues.image.file.originFileObj);

    Api("key-figures", "POST", formData, true)
      .then(() => {
        toastNotification("success", "Support created successfully");
        handleGetKeyFigures();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || "Error while creating support")
      );
  };

  return (
    <Drawer
      title={t("add_key_figures")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button type="primary" form="key-form" key="submit" htmlType="submit">
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="key-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="image"
              label={t("image")}
              rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                className="upload-style"
                maxCount={1}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default CreateKeyFigures;
