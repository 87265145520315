import  React, { useEffect } from "react";
import { Form, Card, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

import logo from "../../public/images/login/login_logo.svg";
import bg from "../../public/images/login/bg.png";
import "../../public/sass/pages/_change-password.scss";

import { toastNotification } from "../../utils/toastNotification";
import Api from "../../api";

const ForgotPassword = ({...props}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem("auc-token", JSON.stringify(getURLParameter('token')));
}, []);

  const getURLParameter = (name) => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null
  }

  const onFinish = (formValues) => {
    Api("auth/reset-password", "POST", formValues)
    .then((response) => {
      toastNotification("success", " Password successfully changed!");
      props.history.push("/login");
    })
    .catch((err) =>
      toastNotification("error", err.message || "Error while creating user")
    );
  };
  return (
    <div
      className="change-password-container"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <div className="content">
        <Card className="card">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <p className="title">{t("reset_password")}</p>
          <Form
            name="inline"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message: `${t("input_password")}`,
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t("new_password")} />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t("input_confirm_password")}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("confirm_password")} />
            </Form.Item>

            <Form.Item>
              <Button
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                {t("change_password")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ForgotPassword;
