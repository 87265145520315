import React from "react";
import { Space, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import "../../../public/sass/components/_filters.scss";

const { Search } = Input;
const { Option } = Select;

export const Filters = ({ onSearch }) => {
  const { t, i18n } = useTranslation();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="wrapper">
      <Space direction="horizontal" className="filters">
        <Select
          defaultValue={t("company")}
          style={{ width: 110 }}
          onChange={handleChange}
          className="filter-style"
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
        <Select
          defaultValue={t("admin")}
          style={{ width: 110 }}
          onChange={handleChange}
          className="filter-style"
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
        <Select
          defaultValue="Status"
          style={{ width: 110 }}
          onChange={handleChange}
          className="filter-style"
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
        </Select>
        <Search
          placeholder={t("search")}
          onSearch={onSearch}
          style={{ width: 110 }}
        />
      </Space>
    </div>
  );
};
