import React, { useState, useEffect } from "react";
import { Filters } from "../../../components/Drawer/Articles/Filters";
import CreateNews from "../../../components/Drawer/News/CreateNews";
import EditNews from "../../../components/Drawer/News/EditNews";
import NewsTable from "../../../components/Tables/News";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Input } from "antd";

import Api from "../../../api";
import "../../../public/sass/pages/_articles.scss";
import { GeneralFormTitle } from "../../../components/GeneralFormTitle";
import { toastNotification } from "../../../utils/toastNotification";

const NewsContainer = () => {
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [generalInfo, setGeneralInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [editData, setData] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllNews = () => {
    Api("generals/type/news", "GET")
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          setVisibleCreate(true);

          setNews(data?.news);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // const handleGetAllNews = () => {
  //   Api("news", "GET", null)
  //     .then((data) => {
  //       setNews(data.news);
  //       setLoading(false);
  //     })
  //     .catch(() => setLoading(false));
  // };

  useEffect(() => {
    handleGetAllNews();
  }, []);

  const getNewsById = (id) => {
    setLoading(true);
    Api("news/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteNews = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("news/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "News was successfully deleted");
        handleGetAllNews();
      })
      .catch(() => toastNotification("error", "Error while deleting news"));
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && !generalInfo?.news?.length) {
      values.type = "news";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setVisibleCreate(true);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setLoading(false);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };

  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
        <Button
          className="button"
          disabled={!isVisibleCreate}
          onClick={() => handleModal("create", true)}
        >
          {t("add_news")}
        </Button>
        <CreateNews
          news={news}
          generalId={generalInfo.id}
          handleGetAllNews={handleGetAllNews}
          isVisible={modalState.mode == "create" && modalState.isOpen}
          onClose={handleModal}
        />
        <EditNews
          data={editData}
          generalId={generalInfo.id}
          id={modalState.id}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
          handleGetAllNews={handleGetAllNews}
          onClose={handleModal}
          news={news}
        />
      </div>
      <GeneralFormTitle
        handleSubmitGeneralForm={handleSubmitGeneralForm}
        generalInfo={generalInfo}
      />
      <NewsTable
        news={news}
        loading={loading}
        getNewsById={getNewsById}
        deleteNews={deleteNews}
      />
    </div>
  );
};
export default NewsContainer;
