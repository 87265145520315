import React, { useState, useEffect } from "react";
import { Filters } from "../../../components/Drawer/Articles/Filters";
import { useTranslation } from "react-i18next";
import { Button, Form, Row, Col, Input } from "antd";

import "../../../public/sass/pages/_articles.scss";

import EditKeyFigures from "../../../components/Drawer/KeyFigures/EditKeyFigures";
import CreateKeyFigures from "../../../components/Drawer/KeyFigures/CreateKeyFigures";
import KeyFiguresTable from "../../../components/Tables/KeyFigures";
import Api from "../../../api";
import { toastNotification } from "../../../utils/toastNotification";

const KeyFiguresContainer = () => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [editData, setData] = useState([]);
  const [key_figures, setKeyFigures] = useState([]);

  const [loading, setLoading] = useState(true);

  const [generalInfo, setGeneralInfo] = useState([]);

  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    handleGetKeyFigures();
  }, []);

  const handleGetKeyFigures = () => {
    Api("generals/type/key-figures", "GET")
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          setVisibleCreate(true);
          form.setFieldsValue(data);

          setKeyFigures(data?.key_figures);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && !generalInfo?.key_figures?.length) {
      values.type = "key-figures";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
          setVisibleCreate(true);
          form.setFieldsValue(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
          form.setFieldsValue(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };

  const getKeyFigureById = (id) => {
    setLoading(true);
    Api("key-figures/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteKeyFigure = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("key-figures/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Fond was successfully deleted");
        handleGetKeyFigures();
      })
      .catch(() => toastNotification("error", "Error while deleting fond"));
  };

  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
        <Button
          className="button"
          onClick={() => handleModal("create", true)}
          disabled={!isVisibleCreate}
        >
          {t("add_key_figures")}
        </Button>
      </div>
      <EditKeyFigures
        data={editData}
        onClose={handleModal}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetKeyFigures={handleGetKeyFigures}
        getKeyFigureById={getKeyFigureById}
        deleteKeyFigure={deleteKeyFigure}
        onClose={handleModal}
        id={modalState.id}
      />

      <CreateKeyFigures
        onClose={handleModal}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        handleGetKeyFigures={handleGetKeyFigures}
        generalId={generalInfo.id}
      />
      <div style={{ paddingTop: 30, paddingBottom: 10 }}>
        <Form
          form={form}
          id="general-form"
          layout="vertical"
          scrollToFirstError
          onFinish={(values) => !isEdit && handleSubmitGeneralForm(values)}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                name="title"
                label={t("Title")}
                style={{ marginRight: 10 }}
                rules={[{ required: true, message: `${t("Title")}` }]}
              >
                <Input placeholder="Title" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                key="submit"
                htmlType="submit"
                className="button"
                form="general-form"
                style={{ marginLeft: 15, marginTop: 28.5 }}
                onClick={() => setEdit(!isEdit)}
              >
                {t(!isEdit ? "Bearbeiten" : "save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <KeyFiguresTable
        getKeyFiguresById={getKeyFigureById}
        loading={loading}
        getKeyFigureById={getKeyFigureById}
        deleteKeyFigure={deleteKeyFigure}
        key_figures={key_figures}
      />
    </div>
  );
};
export default KeyFiguresContainer;
