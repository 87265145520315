import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { dummyRequest } from "../../../utils/dummyRequest";
const { Option } = Select;

export const CreateArticle = ({
  isVisible,
  onClose,
  handleGetAllArticles,
  generalId,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const token = JSON.parse(localStorage.getItem("auc-token"));

  const handleSubmit = (formValues) => {
    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("description", formValues.description);
    formData.append("link", formValues.link);

    formData.append("general_id", generalId);
    formData.append("files", null);

    // let files_array = [
    //   formValues.file.file.originFileObj,
    //   formValues.file.file.originFileObj,
    // ];

    // files_array.forEach((file) => {
    //   formData.append("files", null);
    // });

    Api("articles", "POST", formData, true)
      .then(() => {
        toastNotification("success", "Successfully created article");
        handleGetAllArticles();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while creating article");
      });

    form.resetFields();
  };

  return (
    <Drawer
      title={t("create_new_article")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="users-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="users-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("article_title")}
              rules={[
                { required: true, message: `${t("enter_article_name")}` },
              ]}
            >
              <Input placeholder={t("enter_article_name")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label={t("description")}>
              <Input placeholder={t("enter_article_description")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="link"
              label={t("Url")}
              rules={[
                {
                  type: "url",
                  required: false,
                  message: `${t("Please type url")}`,
                },
              ]}
            >
              <Input placeholder={t("Enter url")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                { required: true, message: `${t("choose_user_status")}` },
              ]}
            >
              <Select placeholder={t("choose_user_status")}>
                <Option value={1}>{t("active")}</Option>
                <Option value={0}>{t("not_active")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} className="d-none">
            <Form.Item name="image" label={t("image")}>
              <Upload
                className="upload-style"
                customRequest={dummyRequest}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12} className="d-none">
            <Form.Item
              name="file"
              label={t("file")}
              // rules={[{ required: true, message: `${t("file")}` }]}
            >
              <Upload maxCount={1} customRequest={dummyRequest}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
