module.exports = {
  login: "Anmeldung",
  email_is_required: "E-Mail ist erforderlich",
  password_is_required: "Passwort ist erforderlich",
  invalid_email: "Die Eingabe ist nicht gültig E-Mail!",
  input_email: "Bitte geben Sie Ihre E-Mail!",
  input_password: "Bitte geben Sie Ihr Passwort!",
  input_confirm_password: "Bitte bestätigen Sie Ihr Passwort!",
  welcome: "Willkommen",
  back: "zurück",
  password: "Kenwort",
  submit: "Erstellen",
  register: "Registrieren",
  confirm_password: "Kennwort Bestätigen",
  remember_me: "Kennwort speichern",
  forgot_password: "Kennwort vergessen",
  not_registered_yet: "Noch nicht registriert",
  register_here: "Hier entlang",
  reset_password: "Passwort zurücksetzen",
  old_password: "Altes Passwort",
  new_password: "Neu Passwort",
  change_password: "Passwort ändern",
  back_to_login: "Zurück zur Anmeldung",
  client: "Verfahren",
  property: "Sachanlagen",
  add_deals: "Deals hinzufügen",
  add_client: "Verfahren hinzufügen",
  add_category: "Kategorie hinzufügen",
  logout: "Abmelden",
  home: "Startseite",
  report: "Gutachten",
  company: "Firma",
  admin: "Verwalter",
  search: "Suche",
  cancel: "Stornieren",
  name: "Name",
  enter_user_name: "Bitte geben sie einen Benutzernamen ein",
  enter_url: "Bitte geben Sie die URL ein",
  owner: "Eigentümer",
  select_owner: "Bitte wählen Sie einen Eigentümer",
  type: "Art",
  choose_type: "Bitte wählen Sie den Typ",
  approver: "Genehmiger",
  choose_approver: "Bitte wählen Sie den Genehmigenden",
  start_date: "Anfangsdatum",
  description: "Beschreibung",
  enter_description: "Bitte geben Sie die URL-Beschreibung ein",
  tel: "Telefon",
  enter_tel: "Bitte geben Sie die Telefonnummer ein",
  customer: "Auftraggeber",
  action: "Aktion",
  add_property: "Sachanlage hinzufügen",
  add_user: "Benutzer hinzufügen",
  view_results: "Ergebnisse anzeigen",
  new_property: "Neuer Sachanlagen",
  property_type: "Güterart",
  value: "Wert",
  designation: "Bezeichnung",
  position: "Positionen",
  enter_number: "Bitte geben Sie die Nummer ein",
  enter_property: "Bitte geben Sie sachanlagen",
  enter_property_type: "Bitte geben Sie den Eigenschaftstyp ein",
  enter_value: "Bitte geben Sie den Wert ein",
  enter_status: "Bitte geben Sie den Status ein",
  enter_designation: "Bitte geben Sie die Bezeichnung ein",
  enter_position: "Bitte geben Sie die Position ein",
  upload_image: "Bild hochladen",

  add_company: "Firma hinzufügen",
  email: "E-Mail",
  phone: "Telefonnummer",
  enter_company_name: "Geben Sie den Firmennamen ein",
  company_type: "Firmenart",
  choose_company_type: "Bitte wählen Sie den Firmentyp",
  owner_firstname: "Inhaber Vorname",
  enter_owner_firstname: "Geben Sie Inhaber Vorname",
  owner_lastname: "Inhaber Nachname",
  enter_owner_lastname: "Geben Sie Inhaber Nachname",
  enter_user_email: "Bitte Benutzer-E-Mail eingeben",
  enter_user_phone: "Bitte geben Sie die Telefonnummer",
  country: "Land",
  enter_user_country: "Bitte geben Sie Land",
  city: "Stadt",
  enter_user_city: "Bitte geben Sie Stadt",
  street: "Straße",
  enter_user_street: "Bitte geben Sie Straße",
  house_number: "Hausnummer",
  enter_user_house_number: "Hausnummer eingeben",
  zip: "PLZ",
  enter_user_zip: "Bitte geben Sie die Postleitzahl ein",
  hrb_number: "HRB-Nummer",
  enter_company_hrb_number: "Bitte geben Sie die USTID-Nummer ein",
  ustid_number: "USTID-Nummer",
  enter_company_ustid_number: "Bitte geben Sie die USTID-Nummer ein",
  tax_number: "Steuernummer",
  enter_company_tax_number: "Bitte geben Sie die Steuernummer",
  role: "Rolle",
  choose_user_role: "Bitte wählen Benutzerrolle",
  firstname: "Vorname",
  lastname: "Nachname",
  enter_user_firstname: "Bitte geben Sie Vorname",
  enter_user_lastname: "Bitte geben Sie Nachname",
  choose_user_company: "Bitte wählen Sie Firma",

  my_profile: "Mein Profil",
  create_new_company: "Neue Firma erstellen",
  create_new_client: "Neuen Verfahren erstellen",
  are_you_sure: "Bist du sicher?",
  active: "Aktiv",
  not_active: "Nicht Aktiv",
  submit_2: "Anmelden",
  choose_user_status: "Bitte Benutzerstatus wählen",

  inventory_number: "Inventurunummer",
  enter_inventory_number: "Bitte geben Sie Inventarnummer",
  product_name: "Produktname",
  enter_product_name: "Bitte Produktname eingeben",
  short_description: "Kurzbeschreibung",
  enter_short_description: "Bitte kurze Beschreibung eingeben",
  category: "Kategorie",
  enter_category: "Bitte Kategorie eingeben",
  quantity: "Menge",
  enter_quantity: "Bitte Menge eingeben",
  packaging_unit: "Verpackungseinheit",
  enter_packaging_unit: "Bitte Verpackungseinheit eingeben",
  qty_packaging_unit: "Menge der VE",
  enter_qty_packaging_unit: "Bitte Menge der VE eingeben",
  assessment_type: "Art des Gutachtens",
  enter_assessment_type: "Bitte Bewertungsart eingeben",
  breakdown_value: "Zerschlagungswert",
  enter_breakdown_value: "Bitte geben Sie den Zerschlagungswert ein",
  premium_percent: "Aufgeldhöhe",
  enter_premium_percent: "Bitte geben Sie den Aufgeldhöhe ein",
  premium: "Aufgeld",
  enter_premium: "Bitte Aufgeld eingeben",
  breakdown_premium: "Zerschlagungswert + Aufgeld",
  enter_breakdown_premium: "Bitte Zerschlagungswert & Aufgeld eingeben",
  subtotal: "Zwischensumme",
  enter_subtotal: "Bitte Zwischensumme eingeben",
  tax_type: "MwSt. - Art",
  enter_tax_type: "Bitte geben Sie MwSt. - Art",
  tax_costs: "MwSt.",
  enter_tax_costs: "Bitte geben Sie MwSt.",
  total_incl_tax: "Gesamtwert inkl. MwSt.",
  enter_total_incl_tax: "Bitte geben Sie Gesamtwert inkl. MwSt.",
  going_concern_value: "Fortführungswert",
  enter_going_concern_value: "Bitte geben Sie Fortführungswert",
  market_value: "Marktwert",
  enter_market_value: "Bitte geben Sie Marktwert",
  comment: "Bemerkung",
  enter_comment: "Bitte geben Sie Bemerkung",
  asset_type: "Vermögensart",
  enter_asset_type: "Bitte geben Sie Vermögensart",
  address_from_product: "Standort des Artikels",
  enter_address_from_product: "Bitte geben Sie Standort des Artikels",
  location_from_product: "Platz des Artikels",
  enter_location_from_product: "Bitte geben Sie Platz des Artikels",

  new_deal: "Neuer Deal",
  enter_admin: "Verwalter name eingeben",
  edit_company: "Firma bearbeiten",
  city_name: "Ort",
  company_number: "Firmennnummer",
  enter_company_number: "Bitte Firmennummer eingeben",
  more_addresses: "Mehrere Adressen",
  more_products: "Mehrere Positionen",
  edit_user: "Benutzer bearbeiten",
  user_number: "Benutzernummer",
  edit_client: "Verfahren bearbeiten",
  product_number: "Produktnummer",
  mileage: "Laufleistung",
  type_of_mileage: "Typ der Laufleistung",
  approved: "Bestätigung",
  accepted: "Akzeptiert",
  other: "Sonstiges",
  rights: "Aus- und absonderungsrechte",
  segregation_rights: "Absonderungsrechte",
  client_number: "Verfahrennummer",
  from_customer: "Vom Kunden",
  enter_customer: "Bitte Kunde eingeben",
  type_of_procedure: "Verfahrensart",
  enter_type_of_procedure: "Bitte geben Sie die Art des Verfahrens ein",
  disposal_costs: "Entsorgungskosten",
  enter_disposal_costs: "Bitte Entsorgungskosten eintragen",
  category_type: "Katgorietyp",
  enter_category_type: "Bitte Kategorietyp eingeben",
  category_number: "Kategorienummer",
  enter_category_name: "Kategorienamen eingeben",
  category_description: "Kategoriebeschreibung",
  enter_category_description: "Bitte Kategoriebeschreibung eingeben",
  subcategory_from: "Sub-Kategorie von",
  choose_subcategory: "Wählen Subkategorie",
  choose_client: "Bitte wählen Sie den Mandant",
  enter_mileage: "Bitte Laufleistung eingeben",
  enter_type_of_mileage: "Bitte geben Sie den Laufleistung ein",
  enter_other: "Bitte geben sie sonstiges",
  select_rights: "Bitte wählen Aus- und absonderungsrechte",
  select_rights_1: "Bitte wählen Absonderungsrechte",
  save: "Speichern",
  edit_category: "Kategorie bearbeiten",
  create_new_category: "Neue Kategorie erstellen",
  edit_customer: "Auftraggeber bearbeiten",
  customer_number: "Auftraggebernummer",
  enter_customer_name: "Bitte geben Sie den Kundennamen ein",
  add_customer: "Auftraggeber hinzufügen",
  third_party: "Drittrechte",
  choose_third_party: "Drittrechte Wählen",
  subcategory: "Sub-Kategorie",
  category_name: "Kategoriename",
  piece: "Stück",
  litre: "Liter",
  assessment: "Gutachten",
  desktop_assessment: "Desktopbewertung",
  create_new_user: "Neuen Benutzer erstellen",
  create_new_customer: "Neuen Auftraggeber erstellen",
  create_new_position: "Neue Position erstellen",
  edit_position: "Position bearbeiten",
  add_position: "Position hinzufügen",
  no_positions_found: "Keine Positionen gefunden, bitte erstellen...",

  add_third_party: "Drittrechte hinzufügen",
  create_new_third_party: "Neues Drittrechte erstellen",
  edit_third_party: "Drittrechte bearbeiten",
  add_open_request: "Anfrage hinzufügen",
  address: "Anschrift",
  user_profile: "Benutzerprofil",
  password_2: "Passwort",

  create_new_article: "Neues Artikel erstellen",
  enter_article_name: "Bitte geben Sie den artikel name ein",
  enter_article_description: "Bitte geben Sie den beschreibung ein",
  add_article: "Artikel hinzufügen",
  article_number: "Artikelnumma",
  article_title: "Name",
  edit_article: "Artikel bearbeiten",

  blog_name: "Blogname",
  headline: "Überschrift",
  subtitle: "Untertitel",
  image: "Bild",
  file: "Datei",
  title: "Titel",
  add_news: "News melden",
  add_support: "Unterstützung hinzufügen",
  add_investment_fond: "Anlagefonds hinzufügen",
  articles: "Artikel",
  edit_website: "Webseite bearbeiten",
  create_website: "Webseite erstellen",
  add_latest_fond: "Neuesten Fond hinzufügen",
  edit_latest_fond: "Letzten Fond bearbeiten",
  add_placed_fond: "Platzierten Fond hinzufügen",
  add_key_figures: "Kennzahlen hinzufügen",
  edit_key_figures: "Kennzahlen bearbeiten",
  edit_placed_fond: "Bearbeiten platziert Fond",
  edit_social_media: "Social Media bearbeiten",
  add_social_media: "Soziale Medien hinzufügen",
  add_sales_service: "Verkaufsservice hinzufügen",
  edit_sales_service: "Vertriebsservice bearbeiten",
  edit_investment_fond: "Anlagefonds bearbeiten",
};
