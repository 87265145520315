import React, { useEffect } from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import Api from "../../../api";
import { toastNotification } from "../../../utils/toastNotification";
import { dummyRequest } from "../../../utils/dummyRequest";
import ApiConstants from "../../../api/ApiConstants";

const EditNews = ({ isVisible, handleGetAllNews, id, onClose, data }) => {
  const [t, i18n] = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("news/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Successfully edited news");
        handleGetAllNews();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while editing news");
      });
    // onClose();
  };

  return (
    <Drawer
      title={t("Edit News")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="fonds-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="fonds-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("title")}
              rules={[{ required: true, message: `${t("title")}` }]}
            >
              <Input placeholder={t("title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="subtitle" label={t("subtitle")}>
              <Input placeholder={t("subtitle")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="image_name"
              label={t("image")}
              // rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                className="upload-style"
                defaultFileList={[{
                  uid: data?.id,
                  name: data?.image_name,
                  status: 'done',
                  url: ApiConstants.IMAGE_URL + data?.image_name,
                  thumbUrl: ApiConstants.IMAGE_URL + data?.image_name,
                }]}
                listType="picture"
                maxCount={1}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="link" label={t("Link")}>
              <Input type="url" placeholder="Link" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default EditNews;
