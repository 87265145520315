import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { toastNotification } from "../../../utils/toastNotification";
import { dummyRequest } from "../../../utils/dummyRequest";

const CreateNews = ({ isVisible, onClose, handleGetAllNews, generalId }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const handleSubmit = (formValues) => {
    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("subtitle", formValues.subtitle);
    formData.append("link", formValues.link);

    formData.append("general_id", generalId);
    formData.append("files", formValues.image.file.originFileObj);

    Api("news", "POST", formData, true)
      .then(() => {
        toastNotification("success", "Successfully created news");
        handleGetAllNews();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || "Error while creating news")
      );
  };

  return (
    <Drawer
      title={t("add_news")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="fonds-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="fonds-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("title")}
              rules={[{ required: true, message: `${t("title")}` }]}
            >
              <Input placeholder={t("title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="subtitle" label={t("subtitle")}>
              <Input placeholder={t("subtitle")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="image"
              label={t("image")}
              rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                className="upload-style"
                maxCount={1}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="link" label={t("Link")}>
              <Input type="url" placeholder="Link" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default CreateNews;
