import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import { toastNotification } from "../../../utils/toastNotification";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { dummyRequest } from "../../../utils/dummyRequest";
import ApiConstants from "../../../api/ApiConstants";

const { Option } = Select;

export const EditArticle = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllArticles,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      let imageData = [];
      let imageStructure = {
        uid: response?.id,
        name: response?.image_name,
        status: "done",
        url: ApiConstants.IMAGE_URL + response?.image_name,
      };
      imageData.push(imageStructure);

      setFileList(imageData);
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("articles/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Successfully edit article");
        handleGetAllArticles();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while creating user");
      });
    // onClose();
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Drawer
      title={t("edit_article")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="article-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="article-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("article_title")}
              rules={[{ required: true, message: `${t("article_number")}` }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label={t("description")}>
              <Input placeholder={t("description")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12} style={{ display: "none" }}>
            <Form.Item
              name="image"
              label={t("image")}
              // rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                className="upload-style"
                fileList={fileList}
                onChange={onChange}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="link"
              label={t("Url")}
              rules={[
                {
                  type: "url",
                  required: false,
                  message: `${t("Please type url")}`,
                },
              ]}
            >
              <Input placeholder={t("Enter url")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                { required: true, message: `${t("choose_user_status")}` },
              ]}
            >
              <Select placeholder={t("choose_user_status")}>
                <Option value={1}>{t("active")}</Option>
                <Option value={0}>{t("not_active")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
