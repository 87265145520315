import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Upload,
  message,
  Tabs,
  Select,
} from "antd";
import { UploadOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import "../../../public/sass/components/drawer/_tabs.scss";
import CreateVideo from "./Videos/CreateVideo";
import CreateWebVideo from "./WebVideos/CreateWebVideo";
import { EditVideos } from "./Videos/EditVideos";
import { EditWebVideos } from "./WebVideos/EditWebVideos";
import { dummyRequest } from "../../../utils/dummyRequest";
import { toastNotification } from "../../../utils/toastNotification";
import CreateDocument from "./Documents/CreateDocument";
import ApiConstants from "../../../api/ApiConstants";

const EditInvestmentFonds = ({
  isVisible,
  onClose,
  handleGetAllFonds,
  data,
  id,
}) => {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);

  const toggleDrawer = () => setVisibleCreate(!isVisibleCreate);

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      console.log(response)
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("fonds/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Successfully edited fond");
        handleGetAllFonds();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while editing fond");
      });
    // onClose();
  };

  const handleOnClickTab = (key) => {
    console.log("key", key);
  };

  return (
    <Drawer
      title={t("edit_investment_fond")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="fond-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="fond-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("headline")}
              rules={[{ required: true, message: `${t("headline")}` }]}
            >
              <Input placeholder={t("headline")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="subtitle" label={t("subtitle")}>
              <Input placeholder={t("subtitle")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="description" label={t("description")}>
              <Input placeholder={t("enter_description")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_placed"
              label={t("Is Placed")}
              rules={[{ required: true, message: `${t("Is Placed")}` }]}
            >
              <Select placeholder={t("Is placed")}>
                <Option value={1}>Yes</Option>
                <Option value={0}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="button_title"
              label={t("Button Title")}
              rules={[{ required: true, message: `${t("Button Title")}` }]}
            >
              <Input placeholder={t("Button Title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="button_link" label={t("Button Link")}>
              <Input placeholder={t("Button Link")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="videos_title"
              label={t("Blog title")}
              rules={[{ required: true, message: `${t("Video's title")}` }]}
            >
              <Input placeholder={t("Video's title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="videos_subtitle" label={t("Video's subtitle")}>
              <Input placeholder={t("Video's subtitle")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="online_title"
              label={t("Online title")}
              rules={[{ required: true, message: `${t("Online title")}` }]}
            >
              <Input placeholder={t("Online title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="online_btn_title"
              label={t("Online button title")}
              rules={[
                { required: true, message: `${t("Online button title")}` },
              ]}
            >
              <Input placeholder={t("Online button title")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="online_btn_link" label={t("Online button link")}>
              <Input placeholder={t("Online button link")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="videos_web_title"
              label={t("Video's web title")}
              rules={[{ required: true, message: `${t("Video's web title")}` }]}
            >
              <Input placeholder={t("Video's web title")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="support_title"
              label={t("Support title")}
              rules={[{ required: true, message: `${t("Support title")}` }]}
            >
              <Input placeholder={t("Support title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="support_subtitle" label={t("Support subtitle")}>
              <Input placeholder={t("Support subtitle")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="blog_title"
              label={t("Video's title")}
              rules={[{ required: true, message: `${t("Blog title")}` }]}
            >
              <Input placeholder={t("Blog title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="show_support"
              label={t("Show support")}
              rules={[{ required: true, message: `${t("Show support")}` }]}
            >
              <Select placeholder={t("Show support")}>
                <Option value={0}>Yes</Option>
                <Option value={1}>No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="image_name"
              label={t("image")}
              // rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                className="upload-style"
                defaultFileList={[
                  {
                    uid: data?.id,
                    name: data?.image_name,
                    status: "done",
                    url: ApiConstants.IMAGE_URL + data?.image_name,
                    thumbUrl: ApiConstants.IMAGE_URL + data?.image_name,
                  },
                ]}
                listType="picture"
                maxCount={1}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="file"
              label={t("file")}
              // rules={[{ required: true, message: `${t("file")}` }]}
            >
              <Upload maxCount={1} customRequest={dummyRequest}>
                <Button icon={<UploadOutlined />}>Click to Upload File</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default EditInvestmentFonds;
