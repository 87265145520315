// Icons
import React from "react";
import {
  LineChartOutlined,
  WechatOutlined,
  AppstoreOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Utils Routes
import Loading from "./shared/Loading";
import NotFound from "./shared/NotFound";

// Authentication Routes
import Login from "../containers/Auth/Login";
import Register from "../containers/Auth/Register";
import ResetPassword from "../containers/Auth/ResetPassword";
import ChangePassword from "../containers/Auth/ChangePassword";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import { EmptyData } from "../components/EmptyData";
import ArticlesContainer from "../containers/Dashboard/Articles/ArticlesContainer";

import "../public/sass/pages/_dashboard.scss";

// Container Routes
import App from "../containers/App";
import InvestmentFondsContainer from "../containers/Dashboard/InvestmentFonds/InvestmentFondsContainer";
import NewsContainer from "../containers/Dashboard/News/NewsContainer";
import SupportContainer from "../containers/Dashboard/Support/SupportContainer";
import HomePage from "../containers/Dashboard/Homepage/HomepageContainer";
import SalesServicesContainer from "../containers/Dashboard/Sales/SalesServicesContainer";
import SocialMediaContainer from "../containers/Dashboard/SocialMedia/SocialMediaContainer";
import KeyFiguresContainer from "../containers/Dashboard/KeyFigures/KeyFiguresContainer";
import LatestFondsContainer from "../containers/Dashboard/LatestFonds/LatestFondsContainer";
import PlacedFondsContainer from "../containers/Dashboard/PlacedFonds/PlacedFondsContainer";
import DocumentsContainer from "../containers/Dashboard/Fonds/Documents";
import VideosContainer from "../containers/Dashboard/Fonds/Videos";
import WebVideosContainer from "../containers/Dashboard/Fonds/WebVideos";

// Lazy loading
const DashboardMain = React.lazy(() =>
  import("../containers/Dashboard/DashboardMain")
);

const ROUTES = [
  {
    path: "/",
    key: "ROOT",
    exact: true,
    component: (props) => <App {...props} />,
  },
  {
    path: "/dashboard",
    key: "Main",
    component: (props) => <DashboardMain {...props} />,
    routes: [
      {
        path: "/dashboard",
        key: "dashboard",
        exact: true,
        categoryName: "Starterseite",
        icon: LineChartOutlined,
        component: (props) => <HomePage {...props} />,
      },
      {
        path: "/dashboard/home",
        key: "home",
        exact: true,
        role: "admin",
        categoryName: "Home",
        icon: AppstoreOutlined,
        component: (props) => <div>Home Screen</div>,
        children: [
          {
            path: "/dashboard/home/articles",
            key: "article",
            categoryName: "Articles",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/home/fonds",
            key: "fonds",
            categoryName: "General Fonds",
            icon: LineChartOutlined,
            children: [
              {
                path: "/dashboard/home/fonds",
                key: "allfonds",
                categoryName: "Fonds",
                icon: LineChartOutlined,
              },
              {
                path: "/dashboard/fonds/documents",
                key: "documents",
                categoryName: "Documents",
                icon: LineChartOutlined,
              },
              {
                path: "/dashboard/fonds/videos",
                key: "videos",
                categoryName: "Videos",
                icon: LineChartOutlined,
              },
              {
                path: "/dashboard/fonds/web-videos",
                key: "web-videos",
                categoryName: "Web Videos",
                icon: LineChartOutlined,
              },
            ]
          },
        ],
      },
      {
        path: "/dashboard/support",
        key: "support",
        exact: true,
        role: "admin",
        categoryName: "Support",
        icon: WechatOutlined,
        component: (props) => <div>Support Screen</div>,
        children: [
          {
            path: "/dashboard/support/sales-service",
            key: "sales-service",
            categoryName: "Sales Service",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/support/web-pages",
            key: "web-pages",
            categoryName: "Web Pages",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/support/social-media",
            key: "social-media",
            categoryName: "Social Media",
            icon: LineChartOutlined,
          },
        ],
      },
      {
        path: "/dashboard/news",
        key: "news",
        exact: true,
        role: "admin",
        categoryName: "News",
        icon: InboxOutlined,
        component: (props) => <div>News Screen</div>,
        children: [
          {
            path: "/dashboard/news/overview",
            key: "overview",
            categoryName: "Overview",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/news/key-figures",
            key: "key-figures",
            categoryName: "Key Figures",
            icon: LineChartOutlined,
          },
        ],
      },
      {
        path: "/dashboard/investment-fonds",
        key: "investment-fonds",
        exact: true,
        role: "admin",
        categoryName: "Investment Fonds",
        icon: InboxOutlined,
        component: (props) => <div>Invenstment Fonds Screen</div>,
        children: [
          {
            path: "/dashboard/investment-fonds/latest-fonds",
            key: "latest-fonds",
            categoryName: "Latest Fonds",
            icon: LineChartOutlined,
          },
          {
            path: "/dashboard/investment-fonds/placed-fonds",
            key: "placed-fonds",
            categoryName: "Placed Fonds",
            icon: LineChartOutlined,
          },
        ],
      },
      {
        hidden: true,
        categoryName: "Home",
        subCategory: "Articles",
        path: "/dashboard/home/articles",
        component: (props) => <ArticlesContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Home",
        subCategory: "Fonds",
        path: "/dashboard/home/fonds",
        component: (props) => <InvestmentFondsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Support",
        subCategory: "Sales Services",
        path: "/dashboard/support/sales-service",
        component: (props) => <SalesServicesContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Support",
        subCategory: "Web Pages",
        path: "/dashboard/support/web-pages",
        component: (props) => <SupportContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Support",
        subCategory: "Social Media",
        path: "/dashboard/support/social-media",
        component: (props) => <SocialMediaContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "News",
        subCategory: "Overview",
        path: "/dashboard/news/overview",
        component: (props) => <NewsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "News",
        subCategory: "Key Figures",
        path: "/dashboard/news/key-figures",
        component: (props) => <KeyFiguresContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Investment Fonds",
        subCategory: "Latest Fonds",
        path: "/dashboard/investment-fonds/latest-fonds",
        component: (props) => <LatestFondsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Investment Fonds",
        subCategory: "Placed Fonds",
        path: "/dashboard/investment-fonds/placed-fonds",
        component: (props) => <PlacedFondsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Documents",
        subCategory: "Fonds",
        path: "/dashboard/fonds/documents",
        component: (props) => <DocumentsContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Videos",
        subCategory: "Fonds",
        path: "/dashboard/fonds/videos",
        component: (props) => <VideosContainer {...props} />,
      },
      {
        hidden: true,
        categoryName: "Web Videos",
        subCategory: "Fonds",
        path: "/dashboard/fonds/web-videos",
        component: (props) => <WebVideosContainer {...props} />,
      },
    ],
  },

  {
    path: "/login",
    key: "AUTH",
    exact: true,
    component: (props) => <Login {...props} />,
  },
  {
    path: "/register",
    key: "AUTH",
    exact: true,
    component: (props) => <Register {...props} />,
  },
  {
    path: "/reset-password",
    key: "AUTH",
    exact: true,
    component: (props) => <ResetPassword {...props} />,
  },
  {
    path: "/change-password",
    key: "AUTH",
    exact: true,
    component: (props) => <ChangePassword {...props} />,
  },
  {
    path: "/forgot-password",
    key: "AUTH",
    exact: true,
    component: (props) => <ForgotPassword {...props} />,
  },
];

export default ROUTES;

export const RenderRoutes = ({ routes }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        {routes.map((route) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route component={(props) => <NotFound {...props} />} />
      </Switch>
    </React.Suspense>
  );
};

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
