import React, { useState } from "react";
import { Table, Space, Divider, Popconfirm, Image } from "antd";
import { columns } from "./columns";
import { useTranslation } from "react-i18next";

import ApiConstants from "../../../api/ApiConstants";

import Edit from "../../../public/images/dashboard/tables/edit.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";

import "../../../public/sass/layout/_index.scss";

const ArticlesTable = ({
  articles,
  loading,
  getArticleById,
  deleteArticle,
}) => {
  const [t, i18n] = useTranslation();
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 50,
      render: (text, record, index) => {
        return (
          <>
            {index+1}
          </>
        )
      }
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Beschreibung",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          <a style={{ color: status == 1 ? "#09CE87" : "#F5222D" }}>
            {status == 1 ? `${t("active")}` : `${t("not_active")}`}
          </a>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          <a onClick={() => getArticleById(e?.id)}>
            <img src={Edit} alt="Edit" />
          </a>
          <Popconfirm
            title={t("are_you_sure")}
            onConfirm={() => deleteArticle(e?.id)}
          >
            <a>
              <img src={Delete} alt="Delete" />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={articles}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
    </div>
  );
};
export default ArticlesTable;
