import React from "react";
import { Table, Divider, Popconfirm, Space, Image } from "antd";
import { useTranslation } from "react-i18next";

import ApiConstants from "../../../api/ApiConstants";

import Edit from "../../../public/images/dashboard/tables/edit.svg";
import Delete from "../../../public/images/dashboard/tables/delete.svg";

import "../../../public/sass/layout/_index.scss";

const KeyFiguresTable = ({
  loading,
  getKeyFigureById,
  deleteKeyFigure,
  key_figures,
}) => {
  const [t, i18n] = useTranslation();
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 50,
      render: (text, record, index) => {
        return (
          <>
            {index+1}
          </>
        )
      }
    },
    {
      title: "Image",
      dataIndex: "image_name",
      key: "image_name",
      render: (img) => (
        <>
          <Image src={ApiConstants.IMAGE_URL + img} width={100} />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (e) => (
        <Space size="middle">
          <a onClick={() => getKeyFigureById(e?.id)}>
            <img src={Edit} alt="Edit" />
          </a>
          <Popconfirm
            title={t("are_you_sure")}
            onConfirm={() => deleteKeyFigure(e?.id)}
          >
            <a>
              <img src={Delete} alt="Delete" />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider />
      <Table
        loading={loading}
        columns={columns}
        dataSource={key_figures}
        className="table"
        scroll={{ y: ApiConstants.TABLE_HEIGHT }}
      />
    </div>
  );
};
export default KeyFiguresTable;
