import React from "react";
import { Drawer, Form, Row, Col, Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { toastNotification } from "../../../utils/toastNotification";

const CreateSupport = ({
  isVisible,
  onClose,
  handleGetAllWebPages,
  generalId,
}) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const handleSubmit = (formValues) => {
    formValues.general_id = generalId;

    Api("web-pages", "POST", formValues)
      .then(() => {
        toastNotification("success", "Support created successfully");
        handleGetAllWebPages();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || "Error while creating support")
      );
  };

  return (
    <Drawer
      title={t("create_website")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="support-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="support-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("title")}
              rules={[{ required: true, message: `${t("title")}` }]}
            >
              <Input placeholder={t("title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="link"
              label={t("Website")}
              rules={[{ required: true, message: `${t("Website")}` }]}
            >
              <Input placeholder={t("Website")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default CreateSupport;
