import React, { useEffect, useState } from "react";
import { Filters } from "../../../components/Drawer/Articles/Filters";
import { Button, Row, Col, Input, Form } from "antd";
import CreateSupport from "../../../components/Drawer/Support/CreateSupport";
import { useTranslation } from "react-i18next";

import Api from "../../../api";

import "../../../public/sass/pages/_articles.scss";
import SupportTable from "../../../components/Tables/Support";
import EditSupport from "../../../components/Drawer/Support/EditSupport";
import { toastNotification } from "../../../utils/toastNotification";

const SupportContainer = () => {
  const [t, i18n] = useTranslation();
  const [form] = Form.useForm();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [web_pages, setWebPages] = useState([]);
  const [editData, setData] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [generalInfo, setGeneralInfo] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllWebPages = () => {
    Api("generals/type/web-pages", "GET")
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          form.setFieldsValue(data);

          setVisibleCreate(true);
          setWebPages(data?.web_pages);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAllWebPages();
  }, []);

  const getSupportById = (id) => {
    setLoading(true);
    Api("web-pages/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteSupport = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("web-pages/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Support was successfully deleted");
        handleGetAllWebPages();
      })
      .catch(() =>
        toastNotification("error", "Error while deleting web_pages")
      );
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && !generalInfo?.web_pages?.length) {
      values.type = "web-pages";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
          setVisibleCreate(true);
          form.setFieldsValue(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
          form.setFieldsValue(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };
  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
        <Button
          className="button"
          onClick={() => handleModal("create", true)}
          disabled={!isVisibleCreate}
        >
          {t("create_website")}
        </Button>
      </div>
      <CreateSupport
        onClose={handleModal}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        handleGetAllWebPages={handleGetAllWebPages}
        web_pages={web_pages}
        generalId={generalInfo.id}
      />
      <EditSupport
        data={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetAllWebPages={handleGetAllWebPages}
        onClose={handleModal}
        web_pages={web_pages}
      />
      <div style={{ paddingTop: 30, paddingBottom: 10 }}>
        <Form
          form={form}
          id="general-form"
          layout="vertical"
          scrollToFirstError
          onFinish={(values) => !isEdit && handleSubmitGeneralForm(values)}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                name="title"
                label={t("Title")}
                style={{ marginRight: 10 }}
                rules={[{ required: true, message: `${t("Title")}` }]}
              >
                <Input placeholder="Title" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                key="submit"
                htmlType="submit"
                className="button"
                form="general-form"
                style={{ marginLeft: 15, marginTop: 28.5 }}
                onClick={() => setEdit(!isEdit)}
              >
                {t(!isEdit ? "Bearbeiten" : "save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <SupportTable
        getSupportById={getSupportById}
        deleteSupport={deleteSupport}
        web_pages={web_pages}
        loading={loading}
      />
    </div>
  );
};
export default SupportContainer;
