import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { toastNotification } from "../../../utils/toastNotification";
import { dummyRequest } from "../../../utils/dummyRequest";
import Api from "../../../api";
import ApiConstants from "../../../api/ApiConstants";

const EditKeyFigures = ({ data, isVisible, onClose }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const handleSubmit = (formValues) => {
    Api("key-figures/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Successfully edit article");
        handleGetAllArticles();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while creating user");
      });
    // onClose();
  };

  return (
    <Drawer
      title={t("edit_key_figures")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="fonds-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="fonds-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="image_name"
              label={t("image")}
              rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                className="upload-style"
                defaultFileList={[{
                  uid: data?.id,
                  name: data?.image_name,
                  status: 'done',
                  url: ApiConstants.IMAGE_URL + data?.image_name,
                  thumbUrl: ApiConstants.IMAGE_URL + data?.image_name,
                }]}
                listType="picture"
                maxCount={1}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default EditKeyFigures;
