import { message } from "antd";
export const beforeUploadFile = (file) => {
  const validateFile = file.type === "application/pdf";
  if (!validateFile) {
    message.error("You can only upload PDF");
    return true;
  } else {
    message.success("Successfully uploaded " + file?.name);
    return false;
  }
};
