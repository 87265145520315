import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Row, Col, Form, Select } from "antd";

export const GeneralFormTitle = ({ handleSubmitGeneralForm, generalInfo }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    form.setFieldsValue(generalInfo);
  }, [generalInfo]);

  const handleSubmit = (values) => {
    if(!isEdit) handleSubmitGeneralForm(values);
  }

  return (
    <>
      <Form
        form={form}
        id="general-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <div style={{ paddingTop: 30, paddingBottom: 10 }}>
          <Row>
            <Col span={6} >
              <Form.Item
                name="title"
                label={t("Title")}
                style={{marginRight: 10}}
                rules={[{ required: true, message: `${t("Title")}` }]}
              >
                <Input placeholder="Title" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="subtitle"
                label={t("Subtitle")}
                rules={[{ required: true, message: `${t("Subtitle")}` }]}
              >
                <Input placeholder="Subtitle" disabled={!isEdit} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                key="submit"
                htmlType="submit"
                className="button"
                form="general-form"
                style={{ marginLeft: 15, marginTop: 28.5 }}
                onClick={() => setEdit(!isEdit)}
              >
                {t(!isEdit ? "Bearbeiten" : "save")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};
