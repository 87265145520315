import React, {useEffect} from 'react';
import {Redirect} from "react-router-dom";

const App = () => {
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      // User is not logged in. Redirect back to login
      return;
    }
  }, []);

  if (localStorage.getItem("token")) return <Redirect to="/dashboard"/>;
  if (!localStorage.getItem("token")) return <Redirect to="/login"/>;
  return <div>Hello, from Auctioline!</div>
};

export default App;