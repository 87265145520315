import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { Filters } from "../../../components/Drawer/Articles/Filters";
import FondsCreateTabs from "../../../components/Drawer/InvestmentFonds/CreateTabs/FondsCreateTabs";
import InvestmentFondsTable from "../../../components/Tables/InvestmentFonds";

import "../../../public/sass/pages/_articles.scss";

import Api from "../../../api";
import { toastNotification } from "../../../utils/toastNotification";
import EditInvestmentFonds from "../../../components/Drawer/InvestmentFonds/EditInvestmentFonds";
import { GeneralFormTitle } from "../../../components/GeneralFormTitle";

const InvestmentFondsContainer = () => {
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [fonds, setFonds] = useState([]);
  const [editData, setData] = useState([]);
  const [generalInfo, setGeneralInfo] = useState([]);
  const [fondId, setFondId] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllFonds = () => {
    Api("generals/type/fonds", "GET", null)
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          setVisibleCreate(true);

          setFonds(data.fonds);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    handleGetAllFonds();
  }, []);

  const getFondById = (id) => {
    setLoading(true);
    Api("fonds/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && !generalInfo?.fonds?.length) {
      values.type = "fonds";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setVisibleCreate(true);
          setGeneralInfo(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setGeneralInfo(data);
          setLoading(false);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };

  const handleCreateFonds = (formValues) => {
    console.log(formValues)
    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("subtitle", formValues.subtitle);
    formData.append("general_id", generalInfo.id);
    formData.append("blog_title", formValues.blog_title);
    formData.append("videos_web_title", formValues.videos_web_title);
    formData.append("button_title", formValues.button_title);
    formData.append("description", formValues.description);
    formData.append("button_link", formValues.button_link);
    formData.append("videos_title", formValues.videos_title);
    formData.append("videos_subtitle", formValues.videos_subtitle);
    formData.append("online_title", formValues.online_title);
    formData.append("online_btn_title", formValues.online_btn_title);
    formData.append("is_placed", formValues.is_placed);
    formData.append("online_btn_link", formValues.online_btn_link);
    formData.append("show_support", formValues.show_support);
    formData.append("support_subtitle", formValues.support_subtitle);
    formData.append("support_title", formValues.support_title);
    let files_array = [
      formValues.image.file.originFileObj,
      formValues.file.file.originFileObj,
    ];
    files_array.forEach((file) => {
      formData.append("files", file);
    });
    Api("fonds", "POST", formData, true)
      .then(() => {
        toastNotification("success", "Successfully created fond");
        handleGetAllFonds();
        handleModal();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while creating fond");
      });
  };

  const deleteFond = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("fonds/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Fond was successfully deleted");
        handleGetAllFonds();
      })
      .catch(() => toastNotification("error", "Error while deleting fond"));
  };

  const createVideos = (formValues) => {
    console.log("formValues===", formValues);
  }


  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
        <Button className="button" onClick={() => handleModal("create", true)}>
          {t("add_investment_fond")}
        </Button>
      </div>

      <FondsCreateTabs
        onClose={handleModal}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        handleGetAllFonds={handleGetAllFonds}
        fonds={fonds}
        fondId={fondId}
        handleCreateFonds={handleCreateFonds}
        createVideos={createVideos}
      />
      <EditInvestmentFonds
        data={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetAllFonds={handleGetAllFonds}
        onClose={handleModal}
        fonds={fonds}
      />
      <GeneralFormTitle
        handleSubmitGeneralForm={handleSubmitGeneralForm}
        generalInfo={generalInfo}
      />
      <InvestmentFondsTable
        getFondById={getFondById}
        fonds={fonds}
        loading={loading}
        deleteFond={deleteFond}
      />
    </div>
  );
};
export default InvestmentFondsContainer;
