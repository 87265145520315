import React, { useEffect, useState } from "react";
import { Form, Tabs, Drawer, Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import "../../../../public/sass/components/drawer/_tabs.scss";
import CreateDocument from "../Documents/CreateDocument";
import { UploadOutlined, PlusCircleTwoTone } from "@ant-design/icons";

import CreateVideo from "../Videos/CreateVideo";
import CreateWebVideo from "../WebVideos/CreateWebVideo";
import CreateInvestmentFonds from "../CreateInvestmentFonds";
import ShowVideos from "../Videos/ShowVideos";
import ShowWebVideo from "../WebVideos/ShowWebVideo";

const { TabPane } = Tabs;

const FondsCreateTabs = ({ onClose, isVisible, handleCreateFonds }) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [fondId, setFondId] = useState("");

  const toggleDrawer = () => setVisibleCreate(!isVisibleCreate);

  // const handleSubmit = (formValues) => {
  //   console.log("formValues", formValues)
    
  // };

  return (
    <Drawer
      title={t("add_investment_fond")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
    >
      <CreateInvestmentFonds
        isVisible={isVisibleCreate}
        onClose={toggleDrawer}
        handleSubmit={handleCreateFonds}
      />
      {/* <Tabs forceRender={true} defaultActiveKey="1" className="tab-style">
        <TabPane
          key="1"
          tab={t("Fonds")}
          className="tab-pane-style"
          onClick={toggleDrawer}
        >
          <CreateInvestmentFonds
            isVisible={isVisibleCreate}
            onClose={toggleDrawer}
            handleSubmit={handleSubmit}
          />
        </TabPane>
        <TabPane key="2" tab={t("Documents")}>
          <Col span={12}>
            <Form.Item name="add_video">
              <Button className="button-style" onClick={toggleDrawer}>
                <PlusCircleTwoTone className="icon-style" /> {t("Add Document")}
              </Button>
            </Form.Item>
          </Col>
          <CreateDocument
            fondId={fondId}
            isVisible={isVisibleCreate}
            onClose={toggleDrawer}
          />
        </TabPane>
        <TabPane key="3" tab={t("Videos")}>
          <Col span={12}>
            <Form.Item name="add_video" onClick={toggleDrawer}>
              <Button className="button-style">
                <PlusCircleTwoTone className="icon-style" /> {t("Add video")}
              </Button>
            </Form.Item>
          </Col>
          <CreateVideo
            fondId={fondId}
            isVisible={isVisibleCreate}
            onClose={toggleDrawer}
            createVideos={createVideos}
          />
        </TabPane>
        <TabPane key="4" tab={t("Web Videos")}>
          <Col span={12}>
            <Form.Item name="add_video" onClick={toggleDrawer}>
              <Button className="button-style">
                <PlusCircleTwoTone className="icon-style" />{" "}
                {t("Add Web Video")}
              </Button>
            </Form.Item>
          </Col>
          <CreateWebVideo
            fondId={fondId}
            isVisible={isVisibleCreate}
            onClose={toggleDrawer}
          />
        </TabPane>
      </Tabs> */}
    </Drawer>
  );
};
export default FondsCreateTabs;
