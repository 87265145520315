import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../api";
import { Button } from "antd";

import { Filters } from "../../../components/Drawer/Articles/Filters";
import { CreateArticle } from "../../../components/Drawer/Articles/CreateArticle";
import ArticlesTable from "../../../components/Tables/Articles";
import { EditArticle } from "../../../components/Drawer/Articles/EditArticle";
import { toastNotification } from "../../../utils/toastNotification";
import "../../../public/sass/pages/_articles.scss";
import { GeneralFormTitle } from "../../../components/GeneralFormTitle";

const ArticlesContainer = () => {
  const { t, i18n } = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [generalInfo, setGeneralInfo] = useState([]);
  const [articles, setArticles] = useState([]);
  const [editData, setData] = useState([]);

  useEffect(() => {
    handleGetAllArticles();
  }, []);

  const onSearch = (value) => console.log(value);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllArticles = () => {
    Api("generals/type/articles", "GET")
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          setVisibleCreate(true);

          setArticles(data?.articles);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getArticleById = (id) => {
    setLoading(true);
    Api("articles/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && !generalInfo?.articles?.length) {
      values.type = "articles";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
          setVisibleCreate(true);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };

  const deleteArticle = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("articles/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Article was successfully deleted");
        handleGetAllArticles();
      })
      .catch(() => toastNotification("error", "Error while deleting article"));
  };

  return (
    <div className="articles-container">
      <div className="header">
        <Filters {...onSearch} />

        <Button
          className="button"
          onClick={() => handleModal("create", true)}
          disabled={!isVisibleCreate}
        >
          {t("add_article")}
        </Button>
      </div>

      <CreateArticle
        onClose={handleModal}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        handleGetAllArticles={handleGetAllArticles}
        generalId={generalInfo.id}
        articles={articles}
      />

      <EditArticle
        data={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetAllArticles={handleGetAllArticles}
        onClose={handleModal}
        articles={articles}
      />
      <GeneralFormTitle
        handleSubmitGeneralForm={handleSubmitGeneralForm}
        generalInfo={generalInfo}
      />
      <ArticlesTable
        articles={articles}
        getArticleById={getArticleById}
        deleteArticle={deleteArticle}
        loading={loading}
      />
    </div>
  );
};
export default ArticlesContainer;
