import React, { useState } from "react";
import { Form, Row, Col, Input, Drawer, Upload, message, Button, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

import Api from '../../../../api';
import { dummyRequest } from "../../../../utils/dummyRequest";
import { toastNotification } from "../../../../utils/toastNotification";

const CreateDocuments = ({ id, onClose, isVisible, getAllDocuments }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();

  const [allFonds, setFonds] = useState([]);

  const handleGetAllFonds = () => {
    Api("generals/type/fonds", "GET", null)
      .then((data) => {
        if (!data || !data?.fonds?.length) {
          toastNotification("info", "You cannot create videos, please go add fonds");
        } else {
          setFonds(data?.fonds);
          // setGeneralInfo(data);
          // setVisibleCreate(true);

          // setFonds(data.fonds);
          // setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = (formValues) => {
    if(!formValues?.fond_id) return message('error', 'Please select at least one fonds')
    const formData = new FormData();
    formData.append("fond_id", formValues.fond_id);
    formData.append("title", formValues.title);
    formData.append("subtitle", formValues.subtitle);
    formData.append("files", formValues.image_name.file.originFileObj);
    formData.append("files", formValues.file_name.file.originFileObj);

    Api("documents/", "POST", formData, true)
      .then(() => {
        toastNotification("success", "Successfully created video");
        onClose();
        getAllDocuments();
      })
      .catch((err) => {
        onClose();
        toastNotification("error", err.error || "Error while creating video");
      });
  };

  return (
    <Drawer
      title="New Documents"
      onClose={onClose}
      visible={isVisible}
      width={720}
      footer={
        <div className="footerstyle">
          <Button onClick={onClose} className="buttonstyle">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="customersform"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="customersform"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="fond_id"
              label={t("Choose Fond")}
              rules={[{ required: true, message: `${t("Choose Fond")}` }]}
            >
              <Select placeholder={t("from_customer")} onClick={() => handleGetAllFonds()}>
                {allFonds?.map((item) => (
                  <Option value={item?.id}>{item?.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("Title")}
              rules={[{ required: true, message: `${t("Enter title")}` }]}
            >
              <Input placeholder={t("Enter title")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="subtitle"
              label={t("Subtitle")}
              rules={[{ required: true, message: `${t("Enter subtitle")}` }]}
            >
              <Input placeholder={t("Enter subtitle")} />
            </Form.Item>
          </Col>
          <Form.Item
            name="image_name"
            label={t("image")}
            rules={[{ required: true, message: `${t("Please add image")}` }]}
          >
            <Upload maxCount={1} customRequest={dummyRequest}>
              <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="file_name"
            label={t("image")}
            rules={[{ required: true, message: `${t("Please add file")}` }]}
          >
            <Upload maxCount={1} customRequest={dummyRequest}>
              <Button icon={<UploadOutlined />}>Click to Upload File</Button>
            </Upload>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};
export default CreateDocuments;
