import React, { useEffect } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import Api from "../../../api";
import "../../../public/sass/components/drawer/_createDrawers.scss";
import { toastNotification } from "../../../utils/toastNotification";

export const EditSales = ({
  isVisible,
  data,
  id,
  onClose,
  handleGetAllSalesSevrices,
}) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const handleSubmit = (formValues) => {
    Api("sales-service/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Support successfully edited");
        handleGetAllSalesSevrices();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while editing support");
      });
  };

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  return (
    <Drawer
      title={t("edit_sales_service")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="article-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="article-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contact"
              label={t("title")}
              rules={[{ required: true, message: `${t("title")}` }]}
            >
              <Input placeholder={t("enter_tel")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
