import React, { useEffect } from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { beforeUploadFile } from "../../../utils/beforeUploadFile";
import { beforeUploadImage } from "../../../utils/beforeUploadImage";
import ApiConstants from "../../../api/ApiConstants";

const EditPlacedFond = ({
  isVisible,
  onClose,
  handleGetAllFonds,
  data,
  id,
}) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    Api("fonds/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Successfully edited fond");
        handleGetAllFonds();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while editing fond");
      });
    // onClose();
  };

  return (
    <Drawer
      title={t("edit_placed_fond")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="fond-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="fond-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("headline")}
              rules={[{ required: true, message: `${t("headline")}` }]}
            >
              <Input placeholder={t("headline")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="subtitle" label={t("subtitle")}>
              <Input placeholder={t("subtitle")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="image"
              label={t("image")}
              // rules={[{ required: true, message: `${t("upload_image")}` }]}
            >
              <Upload
                beforeUpload={beforeUploadImage}
                defaultFileList={[{
                  uid: data?.id,
                  name: data?.image_name,
                  status: 'done',
                  url: ApiConstants.IMAGE_URL + data?.image_name,
                  thumbUrl: ApiConstants.IMAGE_URL + data?.image_name,
                }]}
                listType="picture"
                maxCount={1}
                className="upload-style"
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="file" label={t("file")}>
              <Upload beforeUpload={beforeUploadFile} maxCount={1}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default EditPlacedFond;
