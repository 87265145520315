import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
// import { CreateSales } from "../../../components/Drawer/Sales/CreateSales";
// import { EditSales } from "../../../components/Drawer/Sales/EditSales";
// import { GeneralFormTitle } from "../../../components/GeneralFormTitle";
// import SalesServicesTable from "../../../../components/Tables/Sales";
import { toastNotification } from "../../../../utils/toastNotification";
import Api from "../../../../api";
import { Filters } from "../../../../components/Drawer/Articles/Filters";
import TableDocuments from "./TableDocuments";
import CreateDocuments from "./CreateDocuments";
import { EditDocuments } from "./EditDocuments";

const Documents = () => {
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [generalInfo, setGeneralInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sales_service, setSalesServices] = useState([]);
  const [editData, setData] = useState([]);
  const [allVideos, setVideos] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const getAllDocuments = () => {
    Api("documents", "GET")
      .then((data) => {
        setVideos(data?.videos);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    getAllDocuments();
  }, []);

  const getVideosById = (id) => {
    setLoading(true);
    Api("documents/" + id, "GET", null)
      .then((data) => {
        setData(data);

        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteDocuments = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("documents/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Support was successfully deleted");
        getAllDocuments();
      })
      .catch(() =>
        toastNotification("error", "Error while deleting sales_service")
      );
  };

  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
        <Button className="button" onClick={() => handleModal("create", true)}>
          {t("Create Documents")}
        </Button>
      </div>

      <CreateDocuments
        getAllDocuments={getAllDocuments}
        generalId={generalInfo.id}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        onClose={handleModal}
      />
      <EditDocuments
        data={editData}
        id={modalState.id}
        onClose={handleModal}
        handleGetAllNews={getAllDocuments}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
      />
      <TableDocuments
        allVideos={allVideos}
        deleteDocuments={deleteDocuments}
        getVideosById={getVideosById}
      />
    </div>
  );
};
export default Documents;
