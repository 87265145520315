module.exports = {
  login: "Login",
  email_is_required: "Email is required",
  password_is_required: "Password is required",
  invalid_email: "The input is not a valid email",
  input_email: "Please input your Email!",
  input_password: "Please input your password!",
  input_confirm_password: "Please confirm your password!",
  welcome: "Welcome",
  back: "back",
  password: "Password",
  submit: "Submit",
  register: "Register",
  confirm_password: "Confirm Password",
  remember_me: "Remember Me",
  forgot_password: "Forgot Password",
  not_registered_yet: "Not registered yet",
  register_here: "Register here",
  reset_password: "Reset Password",
  old_password: "Old Password",
  new_password: "New Password",
  change_password: "Change Password",
  back_to_login: "Back to Login",
  client: "Client",
  property: "Property",
  add_deals: "Add Deals",
  add_client: "Add Clients",
  logout: "Logout",
  home: "Home",
  report: "Report",
  company: "Company",
  admin: "Administrator",
  search: "Search",
  create_new_user: "Create a New User",
  cancel: "Cancel",
  name: "Name",
  enter_user_name: "Please enter user name",
  enter_url: "Please enter URL",
  owner: "Owner",
  select_owner: "Please select an owner",
  type: "Type",
  choose_type: "Please choose the type",
  approver: "Approver",
  choose_approver: "Please choose the approver",
  start_date: "Start date",
  description: "Description",
  enter_description: "Please enter URL description",
  tel: "Phone",
  enter_tel: "Please enter phone number",
  customer: "Customer",
  action: "Action",
  add_property: "Add Property",
  add_user: "Add User",
  view_results: "View Results",
  new_property: "New Property",
  property_type: "Property Type",
  value: "Value",
  designation: "Designation",
  position: "Position",
  enter_number: "Please enter number",
  enter_property: "Please enter property",
  enter_property_type: "Please enter property type",
  enter_value: "Please enter value",
  enter_status: "Please enter status",
  enter_designation: "Please enter designation",
  enter_position: "Please enter position",
  upload_image: "Upload Image",

  //CreateUser
  firstname: "Firstname",
  enter_user_firstname: "Please enter firstname",
  lastname: "Lastname",
  enter_user_lastname: "Please enter lastname",
  email: "Email",
  enter_user_email: "Please enter email",
  phone: "Phone number",
  enter_user_phone: "Please enter phone number",
  country: "Country",
  enter_user_country: "Please enter country",
  city: "City",
  enter_user_city: "Please enter city",
  street: "Street",
  enter_user_street: "Please enter street",
  house_number: "House number",
  enter_user_house_number: "Please enter house number",
  zip: "ZIP",
  enter_user_zip: "Please enter zip",
  role: "Role",
  choose_user_role: "Please choose user role",
  choose_user_company: "Please choose company",
  status: "Status",
  choose_user_status: "Please choose status",

  add_company: "Add Company",
  enter_company_name: "Please enter company name",
  owner_firstname: "Owner Firstname",
  enter_owner_firstname: "Please enter owner firstname",
  owner_lastname: "Lastname",
  enter_owner_lastname: "Please enter owner lastname",
  company_type: "Type",
  choose_company_type: "Please choose company type",
  hrb_number: "HRB number",
  enter_company_hrb_number: "Please enter USTID number",
  ustid_number: "HRB number",
  enter_company_ustid_number: "Please enter USTID number",
  tax_number: "TAX number",
  enter_company_tax_number: "Please enter TAX number",

  my_profile: "My Profile",
  create_new_company: "Create New Company",
  create_new_client: "Create New Client",
  are_you_sure: "Are you sure?",
  active: "Active",
  not_active: "Not Active",
  submit_2: "Submit",

  //categories
  create_new_category: "Create new category",
  enter_category_name: "Please enter category name",
  category_position: "Position",
  enter_category_position: "Please enter position",
  decription: "Description",
  enter_category_description: "Please enter description",
  add_category: "Create category",

  create_new_article: "Create new article",
  enter_article_title: "Please enter article title",
  enter_article_description: "Please enter description",
  add_article: "Create article",
  article_title: "Article title",

  inventory_number: "Inventory number",
  enter_inventory_number: "Please enter inventory number",
  product_name: "Product Name",
  enter_product_name: "Please enter product name",
  short_description: "Short description",
  enter_short_description: "Please enter short description",
  category: "Category",
  enter_category: "Please enter category",
  quantity: "Quantity",
  enter_quantity: "Please enter quantity",
  packaging_unit: "Packaging unit",
  enter_packaging_unit: "Please enter packaging unit",
  qty_packaging_unit: "Qty of packaging unit",
  enter_qty_packaging_unit: "Please enter QTY of packaging unit",
  assessment_type: "Type of assessment",
  enter_assessment_type: "Please enter assessment type",
  breakdown_value: "Breakdown value",
  enter_breakdown_value: "Please enter breakdown value",
  premium_percent: "Premium percent",
  enter_premium_percent: "Please enter premium percent",
  premium: "Premium",
  enter_premium: "Please enter premium",
  breakdown_premium: "Breakdown & Premium",
  enter_breakdown_premium: "Please enter Breakdown & Premium",
  subtotal: "Subtotal",
  enter_subtotal: "Please enter subtotal",
  tax_type: "Tax Type",
  enter_tax_type: "Please enter tax type",
  tax_costs: "Tax costs",
  enter_tax_costs: "Please enter tax costs",
  total_incl_tax: "Total incl. Tax",
  enter_total_incl_tax: "Please enter total incl. tax",
  going_concern_value: "Going concern value",
  enter_going_concern_value: "Please enter the going concern value",
  market_value: "Market value",
  enter_market_value: "Please enter market value",
  comment: "Comment",
  enter_comment: "Please enter comment",
  asset_type: "Asset type",
  enter_asset_type: "Please enter asset type",
  address_from_product: "Address from product",
  enter_address_from_product: "Please enter address from product",
  location_from_product: "Location from product",
  enter_location_from_product: "Please enter location form product",

  new_deal: "New Deal",
  enter_admin: "Enter admin name",
  edit_company: "Edit Company",
  city_name: "City",
  company_number: "Company number",
  enter_company_number: "Please enter company number",
  more_addresses: "More Addresses",
  more_products: "More Products",
  edit_user: "Edit User",
  user_number: "User Number",
  edit_client: "Edit Client",
  product_number: "Product number",
  mileage: "Mileage",
  type_of_mileage: "Type of mileage",
  approved: "Approved",
  accepted: "Accepted",
  rights: "Segregation and segregation rights",
  segregation_rights: "Segragation rights",
  client_number: "Client Number",
  from_customer: "From Customer",
  enter_customer: "Please enter customer",
  type_of_prcedure: "Type of procedure",
  enter_type_of_procedure: "Please enter type of procedure",
  disposal_costs: "Disposal costs",
  enter_disposal_costs: "Please enter disposal costs",
  catgory_type: "Category Type",
  enter_category_type: "Please enter category type",
  category_number: "Category Number",
  category_description: "Category Description",
  subcategory_from: "Subcategory from",
  choose_subcategory: "Choose subcategory",
  choose_client: "Please choose client",
  enter_mileage: "Please enter mileage",
  enter_type_of_mileage: "Please enter type of mileage",
  enter_other: "",
  select_rights: "Please select",
  save: "Save",
  edit_category: "Edit Category",
  edit_customer: "Edit Customer",
  customer_number: "Customer Number",
  enter_customer_name: "Please enter the customer name",
  third_party: "Third Party App",
  choose_third_party: "Choose third party",
  subcategory: "Subcategory",
  category_name: "Category name",
  piece: "Piece",
  litre: "Litre",
  assessment: "Assessment",
  desktop_assessment: "Desktop assessment",
  create_new_customer: "Create new customer",
  create_new_position: "Create new position",
  edit_position: "Edit Position",
  add_position: "Add Position",
  no_positions_found: "No positions found, please create...",

  add_third_party: "Add third party",
  create_new_third_party: "Create new third party",
  edit_third_party: "Edit third party",
  add_open_request: "Add open request",
  address: "Address",
  user_profile: "User Profile",
  password_2: "Password",

  blog_name: "Blog name",
  headline: "Headline",
  subtitle: "Subtitle",
  image: "Image",
  file: "File",
  title: "Title",
  add_news: "Add News",
  add_support: "Add support",
  add_investment_fond: "Add investment fond",
  articles: "Articles",
  edit_website: "Edit Web Page",
  create_website: "Create Web Page",
  add_latest_fond: "Add Latest Fond",
  edit_latest_fond: "Edit Latest Fond",
  add_placed_fond: "Add Placed Fond",
  add_key_figures: "Add Key Figures",
  edit_key_figures: "Edit Key Figures",
  edit_placed_fond: "Edit Placed Fond",
  edit_social_media: "Edit Social Media",
  add_social_media: "Add Social Media",
  add_sales_service: "Add Sales Service",
  edit_sales_service: "Edit Sales Service",
  edit_investment_fond: "Edit Investment Fond",
};
