import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Api from "../../../api";

import { Button } from "antd";
import { CreateSales } from "../../../components/Drawer/Sales/CreateSales";
import { EditSales } from "../../../components/Drawer/Sales/EditSales";
import { GeneralFormTitle } from "../../../components/GeneralFormTitle";
import SalesServicesTable from "../../../components/Tables/Sales";
import { Filters } from "../../../components/Drawer/Sales/Filters";
import { toastNotification } from "../../../utils/toastNotification";

const SalesServicesContainer = () => {
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [generalInfo, setGeneralInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sales_service, setSalesServices] = useState([]);
  const [editData, setData] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllSalesSevrices = () => {
    Api("generals/type/sales-service", "GET")
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          setVisibleCreate(true);

          setSalesServices(data?.sales_service);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAllSalesSevrices();
  }, []);

  const getSupportById = (id) => {
    setLoading(true);
    Api("sales-service/" + id, "GET", null)
      .then((data) => {
        setData(data);

        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteSupport = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("sales-service/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Support was successfully deleted");
        handleGetAllSalesSevrices();
      })
      .catch(() =>
        toastNotification("error", "Error while deleting sales_service")
      );
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && !generalInfo?.sales_service?.length) {
      values.type = "sales-service";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
          setVisibleCreate(true);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setLoading(false);
          setGeneralInfo(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };

  return (
    <div className="articles-container">
      <div className="header">
        <Filters />

        <Button
          className="button"
          disabled={!isVisibleCreate}
          onClick={() => handleModal("create", true)}
        >
          {t("add_sales_service")}
        </Button>
      </div>

      <CreateSales
        generalId={generalInfo.id}
        onClose={handleModal}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        handleGetAllSalesSevrices={handleGetAllSalesSevrices}
        sales_service={sales_service}
      />

      <EditSales
        data={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetAllSalesSevrices={handleGetAllSalesSevrices}
        onClose={handleModal}
        sales_service={sales_service}
      />

      <GeneralFormTitle
        handleSubmitGeneralForm={handleSubmitGeneralForm}
        generalInfo={generalInfo}
      />
      <SalesServicesTable
        loading={loading}
        getSupportById={getSupportById}
        deleteSupport={deleteSupport}
        sales_service={sales_service}
      />
    </div>
  );
};
export default SalesServicesContainer;
