import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


import { Button } from "antd";
// import { CreateSales } from "../../../components/Drawer/Sales/CreateSales";
// import { EditSales } from "../../../components/Drawer/Sales/EditSales";
// import { GeneralFormTitle } from "../../../components/GeneralFormTitle";
// import SalesServicesTable from "../../../../components/Tables/Sales";
import { toastNotification } from "../../../../utils/toastNotification";
import Api from "../../../../api";
import TableWebVideo from "./TableWebVideo";
import CreateWebVideos from "./CreateWebVideos";
import { Filters } from "../../../../components/Drawer/Articles/Filters";
import { EditWebVideos } from "./EditWebVideos";

const WebVideos = () => {
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [generalInfo, setGeneralInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sales_service, setSalesServices] = useState([]);
  const [editData, setData] = useState([]);
  const [allVideos, setVideos] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const getAllVideos = () => {
    Api("web-videos", "GET")
      .then((data) => {
        setVideos(data?.videos)
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  const getVideosById = (id) => {
    setLoading(true);
    Api("web-videos/" + id, "GET", null)
      .then((data) => {
        setData(data);

        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteVideos = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("web-videos/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Support was successfully deleted");
        getAllVideos();
      })
      .catch(() =>
        toastNotification("error", "Error while deleting sales_service")
      );
  };
  
  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
            <Button
            className="button"
            onClick={() => handleModal("create", true)}
            >
            {t("Create Web Videos")}
        </Button>
      </div>

      <CreateWebVideos
        getAllVideos={getAllVideos}
        generalId={generalInfo.id}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        onClose={handleModal}
        // videos={videos}
        // handleGetAllNews={handleGetAllNews}
      />
      <EditWebVideos
          data={editData}
          id={modalState.id}
          onClose={handleModal}
          handleGetAllNews={getAllVideos}
          isVisible={modalState.mode == "edit" && modalState.isOpen}
      />

        <TableWebVideo allVideos={allVideos} deleteVideos={deleteVideos} getVideosById={getVideosById} />
    </div>
  );
};
export default WebVideos;
