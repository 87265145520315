import React from "react";
import { Form, Card, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "../../public/sass/pages/_register.scss";
import logo from "../../public/images/login/login_logo.svg";
import bg from "../../public/images/login/bg.png";

const Register = () => {
  const { t, i18n } = useTranslation();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div
      className="register-container"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <div className="content">
        <Card className="card">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <p className="title">{t("register")}</p>
          <Form
            name="inline"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: `${t("invalid_email")}`,
                },
                {
                  required: true,
                  message: `${t("input_email")}`,
                },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: `${t("input_password")}`,
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t("password")} />
            </Form.Item>

            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t("input_confirm_password")}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("confirm_password")} />
            </Form.Item>

            <Form.Item>
              <Button
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                {t("register")}
              </Button>
              <div className="login-text">
                <Link to="/login">
                  {" "}
                  <a>{t("back_to_login")}</a>
                </Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Register;
