import React from "react";
import { Form, Card, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../public/sass/pages/_reset-password.scss";
import logo from "../../public/images/login/login_logo.svg";
import bg from "../../public/images/login/bg.png";

import { toastNotification } from "../../utils/toastNotification";
import Api from "../../api";

const ResetPassword = ({...props}) => {
  const { t, i18n } = useTranslation();

  const onFinish = (formValues) => {
    Api("auth/forgot-password", "POST", formValues)
    .then((response) => {
      toastNotification("success", "Email sent!");
      props.history.push("/login");
    })
    .catch((err) =>
      toastNotification("error", err.message || "Error while sending mail")
    );
  };
  return (
    <div
      className="reset-password-container"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <div className="content">
        <Card className="card">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <p className="title">{t("reset_password")}</p>
          <Form
            name="inline"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: `${t("invalid_email")}`,
                },
                {
                  required: true,
                  message: `${t("input_email")}`,
                },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>

            <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  {t("reset_password")}
                </Button>
                <div className="login-text">
                  <Link to="/login">
                    {" "}
                    <a>{t("back_to_login")}</a>
                  </Link>
                </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ResetPassword;
