import React, { useEffect, useState } from "react";
import { Statistic, Row, Col } from "antd";
import {
  SolutionOutlined,
  BookOutlined,
  SnippetsOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import Api from "../../../api";

const HomePage = () => {
  const [t, i18n] = useTranslation();
  const [articles, setArticles] = useState(0);
  const [fonds, setFonds] = useState(0);
  const [news, setNews] = useState(0);
  const [salesServices, setSalesServices] = useState(0);
  const [webPages, setWebPages] = useState(0);
  const [socialMedias, setSocialMedias] = useState(0);
  const [keyFigures, setKeyFigures] = useState(0);

  useEffect(() => {
    getAllArtilces();
    getAllFonds();
    getAllNews();
    getAllSalesServices();
    getAllWebPages();
    getAllSocialMedia();
    getAllKeyFigures();
  }, []);

  const getAllArtilces = () => {
    Api("articles", "GET", null).then((data) => {
      setArticles(data.articles);
    });
  };
  const getAllFonds = () => {
    Api("fonds", "GET", null).then((data) => {
      setFonds(data.fonds);
    });
  };
  const getAllNews = () => {
    Api("news", "GET", null).then((data) => {
      setNews(data.news);
    });
  };
  const getAllSalesServices = () => {
    Api("supports", "GET", null).then((data) => {
      setSalesServices(data?.supports);
    });
  };
  const getAllWebPages = () => {
    Api("web-pages", "GET", null).then((data) => {
      setWebPages(data?.webpages);
    });
  };
  const getAllSocialMedia = () => {
    Api("social-media", "GET", null).then((data) => {
      setSocialMedias(data?.social_medias);
    });
  };
  const getAllKeyFigures = () => {
    Api("key-figures", "GET", null).then((data) => {
      setKeyFigures(data?.key_figures);
    });
  };


  return (
    <div>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title={t("articles")}
            value={articles?.length || 0}
            prefix={<SnippetsOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Fonds"
            value={fonds?.length || 0}
            prefix={<BookOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Sales Services"
            value={salesServices?.length || 0}
            prefix={<SolutionOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Web Pages"
            value={webPages?.length || 0}
            prefix={<CustomerServiceOutlined />}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{paddingTop: 25}}>
        <Col span={6}>
          <Statistic
            title="Social Media"
            value={socialMedias?.length || 0}
            prefix={<BookOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="News"
            value={news?.length || 0}
            prefix={<BookOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Key Figures"
            value={keyFigures?.length || 0}
            prefix={<BookOutlined />}
          />
        </Col>
      </Row>
    </div>
  );
};
export default HomePage;
