import React from "react";
import { Drawer, Form, Row, Col, Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import { toastNotification } from "../../../utils/toastNotification";

const CreateSocialMedia = ({
  isVisible,
  onClose,
  handleGetAllSocialMedias,
  generalId,
}) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const handleSubmit = (formValues) => {
    formValues.general_id = generalId;

    console.log("here ", formValues);

    Api("social-media", "POST", formValues)
      .then((data) => {
        console.log("data-->", data);
        toastNotification("success", "Support created successfully");
        handleGetAllSocialMedias();
        onClose();
      })
      .catch((err) =>
        toastNotification("error", err.error || "Error while creating support")
      );
  };

  return (
    <Drawer
      title={t("add_social_media")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="support-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="support-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t("title")}
              rules={[{ required: true, message: `${t("title")}` }]}
            >
              <Input placeholder={t("title")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="link"
              label={t("Social media")}
              rules={[{ required: true, message: `${t("Social media")}` }]}
            >
              <Input placeholder={t("Social media")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
export default CreateSocialMedia;
