import ApiConstants from "./ApiConstants";
import axios from "axios";

export default async function api(path, method, params, isFormData) {
  let options;
  let token = JSON.parse(localStorage.getItem("auc-token"));
  options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": !isFormData ? "application/json" : "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    method: method,
    url: ApiConstants.BASE_URL + path,
    ...(params && { data: params }),
  };

  return axios(options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error?.response?.data);
      // if (error?.response?.status === 401) {
      //   return localStorage.clear();
      // }
      // return Promise.reject(error?.response?.data);
    });
}
