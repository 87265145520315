import { message } from "antd";
export const beforeUploadImage = (file) => {
  console.log(file);
  const validateImage =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!validateImage) {
    message.error("You can only upload JPG/PNG/JPEG file!");
    return true;
  } else {
    message.success("Successfully uploaded " + file?.name);
    return false;
  }
};
