import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Filters } from "../../../components/Drawer/Articles/Filters";
import CreateLatestFond from "../../../components/Drawer/LatestFonds/CreateLatestFond";

import "../../../public/sass/pages/_articles.scss";
import Api from "../../../api";
import { toastNotification } from "../../../utils/toastNotification";
import EditLatestFond from "../../../components/Drawer/LatestFonds/EditLatestFond";
import { GeneralFormTitle } from "../../../components/GeneralFormTitle";
import LatestFondsTable from "../../../components/Tables/LatestFonds";

const LatestFondsContainer = () => {
  const [t, i18n] = useTranslation();
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [modalState, setModal] = useState({ id: "", mode: "", isOpen: false });
  const [loading, setLoading] = useState(true);
  const [fonds, setFonds] = useState([]);
  const [editData, setData] = useState([]);
  const [generalInfo, setGeneralInfo] = useState([]);

  const handleModal = (mode, isOpen, id) => {
    setModal({
      ...modalState,
      ["mode"]: mode,
      ["isOpen"]: isOpen,
      ["id"]: id,
    });
  };

  const handleGetAllFonds = () => {
    Api("generals/type/latest-fonds", "GET", null)
      .then((data) => {
        if (!data) {
          toastNotification("info", "Please add general information first!");
          setVisibleCreate(false);
          setLoading(false);
        } else {
          setGeneralInfo(data);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));

    Api("fonds", "GET", null)
      .then((data) => {
        setFonds(data?.fonds?.slice(0, 4) || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    handleGetAllFonds();
  }, []);

  const getFondById = (id) => {
    setLoading(true);
    Api("fonds/" + id, "GET", null)
      .then((data) => {
        setData(data);
        handleModal("edit", true, id);
        setLoading(false);
      })
      .catch((err) => {
        toastNotification("error", err.error || "Something went wrong!");
        setLoading(false);
      });
  };

  const deleteFond = (id) => {
    if (!id) return message.error("Id is missing!");
    Api("fonds/" + id, "DELETE", null)
      .then(() => {
        toastNotification("success", "Fond was successfully deleted");
        handleGetAllFonds();
      })
      .catch(() => toastNotification("error", "Error while deleting fond"));
  };

  const handleSubmitGeneralForm = (values) => {
    if (!generalInfo.length && generalInfo.type !== "latest-fonds") {
      values.type = "latest-fonds";
      Api("generals/", "POST", values)
        .then((data) => {
          setLoading(false);
          setVisibleCreate(true);
          setGeneralInfo(data);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    } else {
      Api("generals/" + generalInfo.id, "PATCH", values)
        .then((data) => {
          setGeneralInfo(data);
          setLoading(false);
        })
        .catch((err) => {
          toastNotification("error", err.error || "Something went wrong!");
          setLoading(false);
        });
    }
  };

  return (
    <div className="articles-container">
      <div className="header">
        <Filters />
        {/* <Button
          className="button"
          onClick={() => handleModal("create", true)}
          disabled={!isVisibleCreate}
        >
          {t("add_latest_fond")}
        </Button> */}
      </div>
      <CreateLatestFond
        onClose={handleModal}
        isVisible={modalState.mode == "create" && modalState.isOpen}
        handleGetAllFonds={handleGetAllFonds}
        fonds={fonds}
      />
      <EditLatestFond
        data={editData}
        id={modalState.id}
        isVisible={modalState.mode == "edit" && modalState.isOpen}
        handleGetAllFonds={handleGetAllFonds}
        onClose={handleModal}
        fonds={fonds}
      />
      <GeneralFormTitle
        handleSubmitGeneralForm={handleSubmitGeneralForm}
        generalInfo={generalInfo}
      />
      <LatestFondsTable
        getFondById={getFondById}
        fonds={fonds}
        loading={loading}
        deleteFond={deleteFond}
      />
    </div>
  );
};
export default LatestFondsContainer;
