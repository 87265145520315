import React, { useEffect, useState } from "react";

import {
  Form,
  Button,
  Col,
  Row,
  Input,
  Upload,
  Tabs,
  Select,
  message,
} from "antd";
import { UploadOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Api from "../../../api";

import "../../../public/sass/components/drawer/_createDrawers.scss";
import "../../../public/sass/components/drawer/_tabs.scss";
import { toastNotification } from "../../../utils/toastNotification";

import { dummyRequest } from "../../../utils/dummyRequest";

const CreateInvestmentFonds = ({
  isVisible,
  onClose,
  handleGetAllFonds,
  generalId,
  id,
  fondId,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { TabPane } = Tabs;
  const token = JSON.parse(localStorage.getItem("auc-token"));
  const [isVisibleCreate, setVisibleCreate] = useState(false);
  const [fondsId, setFondsId] = useState("");

  const toggleDrawer = () => setVisibleCreate(!isVisibleCreate);

  const handleOnClickTab = (key) => {
    console.log("key", key);
  };

  return (
    <Form
      form={form}
      id="fonds-tabs"
      layout="vertical"
      scrollToFirstError
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="title"
            label={t("headline")}
            rules={[{ required: true, message: `${t("headline")}` }]}
          >
            <Input placeholder={t("headline")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="subtitle"
            label={t("subtitle")}
            rules={[{ required: true, message: `${t("subtitle")}` }]}
          >
            <Input placeholder={t("subtitle")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="description"
            label={t("description")}
            rules={[{ required: true, message: `${t("enter_description")}` }]}
          >
            <Input placeholder={t("enter_description")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="is_placed"
            label={t("Is Placed")}
            rules={[{ required: true, message: `${t("Is Placed")}` }]}
          >
            <Select placeholder={t("Is placed")}>
              <Option value={1}>Yes</Option>
              <Option value={0}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="button_title"
            label={t("Button Title")}
            rules={[{ required: true, message: `${t("Button Title")}` }]}
          >
            <Input placeholder={t("Button Title")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="button_link"
            label={t("Button Link")}
            rules={[
              { required: true, message: `${t("Button Link")}` },
              {
                type: "url",
                message: `${t("Button Link")}`,
              },
            ]}
          >
            <Input placeholder={t("Button Link")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="videos_title"
            label={t("Blog title")}
            rules={[{ required: true, message: `${t("Video's title")}` }]}
          >
            <Input placeholder={t("Video's title")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="videos_subtitle"
            label={t("Video's subtitle")}
            rules={[{ required: true, message: `${t("Video's subtitle")}` }]}
          >
            <Input placeholder={t("Video's subtitle")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="online_title"
            label={t("Online title")}
            rules={[{ required: true, message: `${t("Online title")}` }]}
          >
            <Input placeholder={t("Online title")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="online_btn_title"
            label={t("Online button title")}
            rules={[{ required: true, message: `${t("Online button title")}` }]}
          >
            <Input placeholder={t("Online button title")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="online_btn_link"
            label={t("Online button link")}
            rules={[
              { required: true, message: `${t("Online button link")}` },
              {
                type: "url",
                message: `${t("Online button link")}`,
              },
            ]}
          >
            <Input placeholder={t("Online button link")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="videos_web_title"
            label={t("Video's web title")}
            rules={[{ required: true, message: `${t("Video's web title")}` }]}
          >
            <Input placeholder={t("Video's web title")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="support_title"
            label={t("Support title")}
            rules={[{ required: true, message: `${t("Support title")}` }]}
          >
            <Input placeholder={t("Support title")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="support_subtitle"
            label={t("Support subtitle")}
            rules={[{ required: true, message: `${t("Support subtitle")}` }]}
          >
            <Input placeholder={t("Support subtitle")} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="blog_title"
            label={t("Video's title")}
            rules={[{ required: true, message: `${t("Blog title")}` }]}
          >
            <Input placeholder={t("Blog title")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="show_support"
            label={t("Show support")}
            rules={[{ required: true, message: `${t("Show support")}` }]}
          >
            <Select placeholder={t("Show support")}>
              <Option value={0}>Yes</Option>
              <Option value={1}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col
          span={12}
          style={{
            width: "100%",
          }}
        >
          <Form.Item
            name="image"
            label={t("image")}
            rules={[{ required: true, message: `${t("upload_image")}` }]}
          >
            <Upload
              maxCount={1}
              className="upload-style"
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                  file.flag = true;
                  message.error(
                    "Please change file size, to big for app side!"
                  );
                  return false;
                }
                return true;
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="file"
            label={t("file")}
            rules={[{ required: true, message: `${t("file")}` }]}
          >
            <Upload maxCount={1} customRequest={dummyRequest}>
              <Button icon={<UploadOutlined />}>Click to Upload File</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <div className="footer-style">
        <Button onClick={onClose} className="button-style">
          {t("cancel")}
        </Button>
        <Button type="primary" form="fonds-tabs" key="submit" htmlType="submit">
          {t("submit")}
        </Button>
      </div>
    </Form>
  );
};
export default CreateInvestmentFonds;
