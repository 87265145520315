import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

import { toastNotification } from "../../../../utils/toastNotification";
import api from "../../../../api";
import { dummyRequest } from "../../../../utils/dummyRequest";
import ApiConstants from "../../../../api/ApiConstants";

export const EditWebVideos = ({ isVisible, handleGetAllNews, id, onClose, data }) => {
  const [t, i18n] = useTranslation();
  const [form] = Form.useForm();
  const [fonds, setFonds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isVisible) {
      let response = { ...data };
      return form.setFieldsValue(response);
    }
  }, [isVisible]);

  const handleSubmit = (formValues) => {
    api("web-videos/" + id, "PATCH", formValues)
      .then(() => {
        toastNotification("success", "Successfully edited news");
        handleGetAllNews();
        onClose();
      })
      .catch((err) => {
        toastNotification("error", err.error || "Error while editing news");
      });
    // onClose();
  };

  const handleGetAllFonds = () => {
    api("generals/type/fonds", "GET", null)
      .then((data) => {
        if (!data || !data?.fonds?.length) {
          toastNotification("info", "You cannot create videos, please go add fonds");
        } else {
          setFonds(data?.fonds);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <Drawer
      title={t("Edit Videos")}
      width={720}
      onClose={onClose}
      visible={isVisible}
      className="body-style"
      destroyOnClose={true}
      footer={
        <div className="footer-style">
          <Button onClick={onClose} className="button-style">
            {t("cancel")}
          </Button>
          <Button
            type="primary"
            form="fonds-form"
            key="submit"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        id="fonds-form"
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="fond_id"
              label={t("Choose Fond")}
              rules={[{ required: true, message: `${t("Choose Fond")}` }]}
            >
              <Select placeholder={t("from_customer")} onClick={() => handleGetAllFonds()}>
                {fonds?.map((item) => (
                  <Option value={item?.id}>{item?.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t("name")}
              rules={[{ required: true, message: `${t("Enter video name")}` }]}
            >
              <Input placeholder={t("Enter video name")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="link"
              label={t("Link")}
              rules={[{ required: true, message: `${t("Add link")}` }]}
            >
              <Input type="url" placeholder={t("Add link")} />
            </Form.Item>
          </Col>
          <Form.Item
            name="image_name"
            label={t("image")}
            rules={[{ required: true, message: `${t("Please add image")}` }]}
          >
            <Upload 
            disabled
            defaultFileList={[{
                  uid: data?.id,
                  name: data?.image_name,
                  status: 'done',
                  url: ApiConstants.IMAGE_URL + data?.image_name,
                  thumbUrl: ApiConstants.IMAGE_URL + data?.image_name,
              }]}
                listType="picture" maxCount={1} customRequest={dummyRequest}>
              {/* <Button icon={<UploadOutlined />}>Click to Upload</Button> */}
            </Upload>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};
